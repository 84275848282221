var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.confirmModalVisible
    ? _c(
        "div",
        {
          staticClass: "modal modal-sticky-bottom-right modal-sticky-lg show",
          staticStyle: { display: "block" },
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content kt-inbox" }, [
              _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__head" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "kt-portlet__head-label float-right" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-icon",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.cancelFunction.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "la la-close" })]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "kt-section" }, [
                    _vm.header
                      ? _c("div", { staticClass: "kt-section__title" }, [
                          _vm._v(" " + _vm._s(_vm.header) + " "),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "kt-section__info" }, [
                      _vm._v(" " + _vm._s(_vm.message) + " "),
                    ]),
                    _c("div", { staticClass: "kt-section__content" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-secondary mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.cancelFunction.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn mr-2",
                          class: {
                            "btn-danger": _vm.color === "Red",
                            "btn-warning": _vm.color === "Orange",
                            "btn-success": _vm.color === "Green",
                            "btn-primary": _vm.color === "Blue",
                            "kt-spinner kt-spinner--sm kt-spinner--light":
                              _vm.processing,
                          },
                          attrs: { type: "button", disabled: _vm.processing },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.confirmFunction.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.processing ? "Processing" : "Confirm"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", {
                    staticClass:
                      "kt-separator kt-separator--space-sm kt-separator--border-dashed",
                  }),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("span", { staticClass: "kt-portlet__head-icon kt-hidden" }, [
        _c("i", { staticClass: "la la-gear" }),
      ]),
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Confirm "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }