<template>
<div
    v-if="confirmModalVisible"
    class="modal modal-sticky-bottom-right modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block"
>
    <div class="modal-dialog">
        <div class="modal-content kt-inbox">
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon kt-hidden">
                            <i class="la la-gear" />
                        </span>
                        <h3 class="kt-portlet__head-title">
                            Confirm
                        </h3>
                    </div>
                    <div class="kt-portlet__head-label float-right">
                        <a
                            href="#"
                            class="btn btn-icon"
                            @click.stop.prevent="cancelFunction"
                        >
                            <i class="la la-close" />
                        </a>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-section">
                        <div
                            v-if="header"
                            class="kt-section__title"
                        >
                            {{ header }}
                        </div>
                        <div class="kt-section__info">
                            {{ message }}
                        </div>
                        <div class="kt-section__content">
                            <button
                                type="button"
                                class="btn btn-outline-secondary mr-2"
                                @click.stop.prevent="cancelFunction"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                class="btn mr-2"
                                :class="{
                                    'btn-danger': color === 'Red',
                                    'btn-warning': color === 'Orange',
                                    'btn-success': color === 'Green',
                                    'btn-primary': color === 'Blue',
                                    'kt-spinner kt-spinner--sm kt-spinner--light': processing,
                                }"
                                :disabled="processing"
                                @click.stop.prevent="confirmFunction"
                            >
                                {{ processing ? 'Processing' : 'Confirm' }}
                            </button>
                        </div>
                    </div>
                    <div class="kt-separator kt-separator--space-sm  kt-separator--border-dashed" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'ConfirmModal',
    mixins: [],
    props: {
        confirmModalVisible: {
            type: Boolean,
            required: true,
        },
        cancelFunction: {
            type: Function,
            required: true,
        },
        confirmFunction: {
            type: Function,
            required: true,
        },
        processing: {
            type: Boolean,
            required: false,
            default: false,
        },
        header: {
            type: String,
            required: false,
            default: null,
        },
        message: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'Blue',
        },
    },
    computed: {

    },
    methods: {

    },
};
</script>
