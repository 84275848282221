<template>
<div
    v-if="reportSearchVisible"
    class="modal modal-sticky-bottom-right modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block"
>
    <div class="modal-dialog">
        <div class="modal-content kt-inbox">
            <div
                v-if="!deleting"
                class="kt-portlet"
            >
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon kt-hidden">
                            <i class="la la-gear" />
                        </span>
                        <h3 class="kt-portlet__head-title">
                            {{ report.reportType }}
                        </h3>
                    </div>
                    <div class="kt-portlet__head-label float-right">
                        <a
                            href="#"
                            class="btn btn-icon"
                            @click.stop.prevent="closeModal"
                        >
                            <i class="la la-close" />
                        </a>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-section">
                        <div class="kt-section__title kt-font-bold">
                            Apply Filters to pull {{ report.reportType }} by
                        </div>
                        <div class="kt-section__content">
                            <div class="form-group row">
                                <div class="col-lg-9">
                                    <vue-tags-input
                                        v-model="filter"
                                        :placeholder="forSchoolWide ? '' : 'By School-Wide, Course, Grade Level, Homeroom...'"
                                        :tags="filters"
                                        :autocomplete-items="filteredItems"
                                        :add-only-from-autocomplete="true"
                                        @tags-changed="newFilters => filters = newFilters"
                                    />
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-success btn-bold pull-right"
                                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': generating}"
                                    :disabled="filters.length === 0 || generating"
                                    @click.stop.prevent="pullReport"
                                >
                                    {{ generating ? 'Pulling' : 'Pull Report' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="kt-portlet"
            >
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon kt-hidden">
                            <i class="la la-gear" />
                        </span>
                        <h3 class="kt-portlet__head-title">
                            Confirm Delete {{ report.reportType }}
                        </h3>
                    </div>
                    <div class="kt-portlet__head-label float-right">
                        <a
                            href="#"
                            class="btn btn-icon"
                            @click.stop.prevent="closeModal"
                        >
                            <i class="la la-close" />
                        </a>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-section">
                        <div class="kt-section__info">
                            Deleting this {{ report.reportType }} could cause the report for other courses to be deleted if they were generated together.
                        </div>
                        <div class="kt-section__content">
                            <button
                                type="button"
                                class="btn btn-outline-secondary mr-2"
                                @click.stop.prevent="deleting = false"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger btn-bold mr-2"
                                @click.stop.prevent="deleteReport"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="canBeDeleted"
                class="modal-footer"
            >
                <button
                    v-if="!deleting"
                    type="button"
                    class="btn btn-label-danger btn-bold"
                    @click.stop.prevent="deleting = true"
                >
                    Delete Report
                </button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import VueTagsInput from '@johmun/vue-tags-input';
import * as network from '../network';
import teacherMixins from '../store/mixins/teacherMixins';
import { getCourses, CourseFilter } from '../store/mixins/courseMixins';

export default {
    name: 'ReportSearchModal',
    components: {
        VueTagsInput,
    },
    mixins: [teacherMixins],
    props: {
        report: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        openReportPdf: {
            type: Function,
            required: true,
        },
        reportSearchVisible: {
            type: Boolean,
            required: true,
        },
        closeReportSearch: {
            type: Function,
            required: true,
        },
        removeReport: {
            type: Function,
            required: true,
        },
        courses: {
            type: Array,
            required: false,
            default: () => [],
        },
        homeRooms: {
            type: Array,
            required: false,
            default: () => [],
        },
        gradeLevels: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            generating: false,
            deleting: false,
            filter: '',
            filters: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
        }),
        teacherCourses() {
            const { school, $store } = this;
            const { database } = $store.state;
            const { schoolStaffId } = school;
            return getCourses(database, new CourseFilter({ schoolStaffId }));
        },
        filteredCourses() {
            const { courses } = this;
            if (!courses) return [];
            return courses.map((c) => ({
                value: c.courseSectionId,
                text: `${c.extCourseSectionId} ${c.courseSectionTitle}`,
                type: 'course',
            }));
        },
        filteredGradeLevels() {
            const { gradeLevels } = this;
            if (!gradeLevels) return [];
            return gradeLevels.map((gl) => {
                let text = gl;
                if (gl.charAt(0) === '0') text = gl.substring(1);
                if (text === '0' || text === '00') text = 'K';

                return {
                    text: `Grade ${text}`,
                    value: gl,
                    type: 'gradeLevel',
                };
            });
        },
        filteredHomeRooms() {
            const { homeRooms } = this;
            if (!homeRooms) return [];
            return homeRooms.map((hr) => ({
                text: `Homeroom ${hr}`,
                value: hr,
                type: 'homeRoom',
            }));
        },
        filteredStudentGroups() {
            return this.$store.state.database.studentGroups.filter((g) => !g.deleted).map((g) => ({
                text: `Student Group: ${g.groupName}`,
                value: g.studentGroupId,
                type: 'studentGroup',
            })) || [];
        },
        filteredItems() {
            const {
                filteredCourses, filteredGradeLevels, filteredHomeRooms,
                filteredStudentGroups, forSchoolWide,
            } = this;
            if (forSchoolWide) return [];

            return [
                ...filteredCourses,
                ...filteredGradeLevels,
                ...filteredHomeRooms,
                ...filteredStudentGroups,
                {
                    text: 'School-Wide',
                    value: 'All',
                    type: 'all',
                },
            ].filter((item) => {
                const { text, type } = item;
                const { filter } = this;
                if (!filter) return true;
                if (type === 'course') return `Course ${text}`.toLowerCase().includes(filter.toLowerCase());
                return text.toLowerCase().includes(filter.toLowerCase());
            });
        },
        forSchoolWide() {
            const { filters } = this;
            const hasAll = filters.find((f) => f.type === 'all');
            return hasAll;
        },
        canBeDeleted() {
            const { report, school } = this;
            if (!report) return false;
            if (report.reportType === 'Report Card') return false;
            const { schoolStaffId } = school;
            return report.schoolStaffId == schoolStaffId;
        },
    },
    methods: {
        closeModal() {
            this.deleting = false;
            this.filter = '';
            this.filters = [];
            this.closeReportSearch();
        },
        pullReport() {
            const v = this;
            const {
                report, filters, school, forSchoolWide,
            } = v;
            if (!report) return;
            if (!filters || !filters.length) return;
            if (v.generating) return;
            v.generating = true;

            const { reportId, reportParams } = report;
            const { reportType } = reportParams;

            const limitBy = {
                allForReport: false,
                reportType,
                courseSectionIds: [],
                homeRooms: [],
                gradeLevels: [],
                studentGroupIds: [],
            };

            if (forSchoolWide) {
                const { role } = school;
                if (!['School Admin'].includes(role)) {
                    const { teacherCourses } = this;
                    if (teacherCourses && teacherCourses.length) {
                        limitBy.courseSectionIds = teacherCourses.map((c) => c.courseSectionId);
                    } else {
                        const { filteredGradeLevels, filteredHomeRooms } = this;
                        limitBy.gradeLevels = filteredGradeLevels.map((gl) => gl.value);
                        limitBy.homeRooms = filteredHomeRooms.map((hr) => hr.value);
                    }
                } else limitBy.allForReport = true;
            } else {
                filters.forEach((f) => {
                    const { type, value } = f;
                    if (type === 'course') limitBy.courseSectionIds.push(value);
                    if (type === 'gradeLevel') limitBy.gradeLevels.push(value);
                    if (type === 'homeRoom') limitBy.homeRooms.push(value);
                    if (type === 'studentGroup') limitBy.studentGroupIds.push(value);
                });
            }

            const { schoolId, schoolTermId } = school;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    reportId,
                },
                body: {
                    limitBy,
                },
            };

            network.reports.getReportPdf(params, (err, res) => {
                v.generating = false;
                if (err) return v.showError('Unable to pull pdf for report');
                const { pdf } = res;
                if (!pdf) return v.showError('No students for grouping found for report');
                const url = URL.createObjectURL(new Blob([new Uint8Array(pdf.data).buffer], { type: 'application/pdf' }));
                this.closeModal();
                this.openReportPdf(url);
            });
        },
        deleteReport() {
            const v = this;
            if (v.generating) return;
            v.generating = true;

            const { report, school, canBeDeleted } = v;
            if (!report || !canBeDeleted) return v.showError('Unable to delete report');

            const { schoolId, schoolTermId } = school;
            const { reportId } = report;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    reportId,
                },
            };

            network.reports.deleteReport(params, (err, res) => {
                v.generating = false;
                if (err) return v.showError('Unable to delete report');
                this.removeReport(reportId);
                this.closeModal();
            });
        },
    },
};
</script>
