var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.reportSearchVisible
    ? _c(
        "div",
        {
          staticClass: "modal modal-sticky-bottom-right modal-sticky-lg show",
          staticStyle: { display: "block" },
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content kt-inbox" }, [
              !_vm.deleting
                ? _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _c("div", { staticClass: "kt-portlet__head-label" }, [
                        _vm._m(0),
                        _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" " + _vm._s(_vm.report.reportType) + " "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "kt-portlet__head-label float-right" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-icon",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.closeModal.apply(null, arguments)
                                },
                              },
                            },
                            [_c("i", { staticClass: "la la-close" })]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _c("div", { staticClass: "kt-section" }, [
                        _c(
                          "div",
                          { staticClass: "kt-section__title kt-font-bold" },
                          [
                            _vm._v(
                              " Apply Filters to pull " +
                                _vm._s(_vm.report.reportType) +
                                " by "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "kt-section__content" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "div",
                              { staticClass: "col-lg-9" },
                              [
                                _c("vue-tags-input", {
                                  attrs: {
                                    placeholder: _vm.forSchoolWide
                                      ? ""
                                      : "By School-Wide, Course, Grade Level, Homeroom...",
                                    tags: _vm.filters,
                                    "autocomplete-items": _vm.filteredItems,
                                    "add-only-from-autocomplete": true,
                                  },
                                  on: {
                                    "tags-changed": (newFilters) =>
                                      (_vm.filters = newFilters),
                                  },
                                  model: {
                                    value: _vm.filter,
                                    callback: function ($$v) {
                                      _vm.filter = $$v
                                    },
                                    expression: "filter",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-success btn-bold pull-right",
                                class: {
                                  "kt-spinner kt-spinner--sm kt-spinner--light":
                                    _vm.generating,
                                },
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.filters.length === 0 || _vm.generating,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.pullReport.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.generating ? "Pulling" : "Pull Report"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _c("div", { staticClass: "kt-portlet__head-label" }, [
                        _vm._m(1),
                        _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(
                            " Confirm Delete " +
                              _vm._s(_vm.report.reportType) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "kt-portlet__head-label float-right" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-icon",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.closeModal.apply(null, arguments)
                                },
                              },
                            },
                            [_c("i", { staticClass: "la la-close" })]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _c("div", { staticClass: "kt-section" }, [
                        _c("div", { staticClass: "kt-section__info" }, [
                          _vm._v(
                            " Deleting this " +
                              _vm._s(_vm.report.reportType) +
                              " could cause the report for other courses to be deleted if they were generated together. "
                          ),
                        ]),
                        _c("div", { staticClass: "kt-section__content" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-secondary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.deleting = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger btn-bold mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.deleteReport.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" Confirm ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
              _vm.canBeDeleted
                ? _c("div", { staticClass: "modal-footer" }, [
                    !_vm.deleting
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-label-danger btn-bold",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                _vm.deleting = true
                              },
                            },
                          },
                          [_vm._v(" Delete Report ")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "kt-portlet__head-icon kt-hidden" }, [
      _c("i", { staticClass: "la la-gear" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "kt-portlet__head-icon kt-hidden" }, [
      _c("i", { staticClass: "la la-gear" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }